
import Vue from 'vue';

import Icon from 'UI/Icon.vue';
import { composedPath } from 'Lib/utils/eventPathChecker';

/*
* emits
* select-item: () => string;
* */

interface Props {
    title?: string;
    items?: string[];
    activeItem?: string;
    placeholder?: string;
}

interface Data {
    showSelect: boolean;
}

interface Methods {
    selectItem: (data: string) => void;
    onWindowClick: (data: PointerEvent) => void;
}

type NoData = Record<string, never>;

export default Vue.extend<Data, Methods, NoData, Props>({
    components: {
        Icon,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
        activeItem: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            showSelect: false,
        };
    },
    methods: {
        selectItem(item) {
            this.$emit('select-item', item);
            this.showSelect = false;
        },
        onWindowClick(e) {
            const path = composedPath(e.target);
            if (!path.some((node) => node.classList && Array.from(node.classList).includes('select-items'))) {
                this.showSelect = false;
            }
        },
    },
    created() {
        window.addEventListener('click', this.onWindowClick as any);
    },
    beforeDestroy() {
        window.removeEventListener('click', this.onWindowClick as any);
    },
});
